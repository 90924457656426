/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  var MAIN = {};

  MAIN.setMenu = function () {
    const toggleMenu = function() {
      $('body').toggleClass('menu-active');
    };
    $('.icon__menu').on('click', toggleMenu);

    const bst = $('.banner').offset().top;

    $(window).scroll(function() {
      if ($(this).scrollTop() > bst){
        $('body').addClass('is-sticky');
      } else {
        $('body').removeClass('is-sticky');
      }
    });
  };

  MAIN.setLang = function() {
    const l = $('.bogo-language-switcher > li');
    const c = $('.entry-content');

    l.each(function() {
      if ( !$('a', this)[0] && !$(this).hasClass('current') ) {
        $(this).hide();
      }
    });

    if (l.hasClass('ja current')) {
      c.addClass('text-sans-serif');
    }
  };

  MAIN.setSwiper = function() {
    const swiper = new Swiper('.swiper-container', {
      pagination: {
        el: '.swiper-pagination',
      },
      grabCursor: true,
      watchOverflow: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    });
  };

  MAIN.setIas = function()
  {
    var ias = $.ias({
      container:      '.ias-container',
      item:           '.ias-item',
      pagination:     '.navigation',
      next:           '.nav-links .next',
      negativeMargin: 600
    });

    ias.extension(new IASSpinnerExtension({
      src: '/wp-content/themes/partners-magazine/dist/images/brand.svg'
    }));

    ias.extension(new IASTriggerExtension({
      text: 'MORE'
    }));
  };

  MAIN.setTagline = function() {
    var style = '<link rel="stylesheet" href="/wp-content/themes/partners-magazine/dist/styles/tagline.css">';
    $('head link:last').after(style);
  };

  MAIN.setAccordion = function() {
    var trigger = $('.accordion__trigger');
    var target = $('.accordion__target');
    trigger.on('click', function(){
      $(this).toggleClass('is-expanded');
      target.toggleClass('is-expanded');
    });
  };

  MAIN.setCover = function() {
    lottie.loadAnimation({
      container: document.querySelector('.cover__brand'),
      renderer: 'svg',
      autoplay: true,
      loop: false,
      path: 'wp-content/themes/partners-magazine/dist/data/logo.json' // the path to the animation json
    });
  };

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    'common': {
      init: function() {
        MAIN.setMenu();
      },
      finalize: function() {
        MAIN.setTagline();
      }
    },
    'home': {
      init: function() {
        MAIN.setSwiper();
        MAIN.setAccordion();
        //MAIN.setCover();
      }
    },
    'post_type_archive': {
      init: function() {
        MAIN.setIas();
      }
    },
    'single_store': {
      init: function() {
        MAIN.setSwiper();
        MAIN.setIas();
      }
    },
    'single_story': {
      init: function() {
        MAIN.setLang();
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
